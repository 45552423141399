import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import RichTooltipContent from '~/base/components/RichTooltipContent';
import SectionTipCard from '~/base/components/SectionTipCard';
import SubHeader from '~/base/components/SubHeader/SubHeader';
import usePageTitle from '~/hooks/usePageTitle';
import { SongType, useSongByIdQuery } from '~/types/generated/graphql';
import { Translator } from '~/types/Translator';
import ISWC from '../ISWC';
import Lyrics from '../Lyrics/Lyrics';
import RecordingsList from '../Recordings/RecordingsList';
import RegistrationStatus from '../RegistrationStatus/RegistrationStatus';
import SongInformation from '../SongInformation/SongInformation';
import SongwritersTable from '../Songwriters/SongwritersTable';

function EditSongPage({ t }: Translator) {
  const lyricGuidelines = [] as Array<string>;
  const LYRIC_GUIDELINE_COUNT = 14;
  for (let i = 1; i <= LYRIC_GUIDELINE_COUNT; i += 1) {
    lyricGuidelines.push(t(`form.tips.lyrics.expanded-content.${i}`));
  }

  const altTitleGuidelines = [] as Array<string>;
  const ALT_TITLE_GUIDELINE_COUNT = 4;
  for (let i = 1; i <= ALT_TITLE_GUIDELINE_COUNT; i += 1) {
    altTitleGuidelines.push(
      t(`form.tips.alternate-titles.expanded-content.${i}`),
    );
  }

  const navigate = useNavigate();
  const [currentSong, setCurrentSong] = useState<SongType | undefined>(
    undefined,
  );
  const { id: songId } = useParams();
  const { loading } = useSongByIdQuery({
    variables: {
      id: parseInt(songId || '0', 10),
    },
    onCompleted: (res) => {
      setCurrentSong(res.song as SongType);
    },
  });

  const formMethods = useForm({
    mode: 'onBlur',
    defaultValues: useMemo(() => {
      return currentSong || {};
    }, [currentSong]),
    shouldUseNativeValidation: true,
  });

  usePageTitle(currentSong?.title || '');

  useEffect(() => {
    formMethods.reset(currentSong);
  }, [currentSong]);

  return (
    <form
      data-testid="edit-song-page"
      onSubmit={formMethods.handleSubmit(() => {
        // TODO: Handle form submission
      })}
    >
      <FormProvider {...formMethods}>
        <Container sx={{ flexGrow: 1 }}>
          <SubHeader
            title={loading ? '...' : currentSong?.title}
            breadcrumb={t('page.titles.backToSongs')}
            breadcrumbLink="/songs/manage"
          />

          {/* Body */}
          <Grid container>
            <Grid item xs={12} md={9}>
              <Box sx={{ pr: { md: 3, xs: 0 } }}>
                <SongInformation t={t} loading={loading} />
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ mb: 3 }} />
              <SectionTipCard
                title={t('form.tips.alternate-titles.title')}
                color="blue"
                readMoreLink="/help/alternate-song-titles"
                readMoreText={t('form.tips.alternate-titles.button')}
                alertContent={
                  <RichTooltipContent
                    title={t('form.tips.alternate-titles.title')}
                    type="ol"
                    contents={altTitleGuidelines}
                  />
                }
              >
                <Typography
                  variant="body1"
                  component="span"
                  dangerouslySetInnerHTML={{
                    __html: t('form.tips.alternate-titles.content') as string,
                  }}
                />
              </SectionTipCard>
            </Grid>
          </Grid>

          {/* Songwriters */}
          <Grid container>
            <Grid item xs={12} md={9}>
              <Box sx={{ pr: { md: 3, xs: 0 } }}>
                <SongwritersTable t={t} ownerships={currentSong?.ownerships} />
              </Box>
            </Grid>
          </Grid>

          {/* Registration Status */}
          <Grid container>
            <Grid item xs={12} md={9}>
              <Box sx={{ pr: { md: 3, xs: 0 } }}>
                <RegistrationStatus t={t} song={currentSong} />
              </Box>
            </Grid>
            <Grid item xs={12} md={3} sx={{ pt: { md: 5, xs: 0 } }} />
          </Grid>

          {/* Recordings */}
          <Grid container>
            <Grid item xs={12} md={9}>
              <Box sx={{ pr: { md: 3, xs: 0 } }}>
                <RecordingsList t={t} song={currentSong} />
              </Box>
            </Grid>
            <Grid item xs={12} md={3} sx={{ pt: { md: 5, xs: 0 } }} />
          </Grid>

          {/* ISCW, Lyrics, Chrous Lyrics */}
          <Grid container>
            <Grid item xs={12} md={9}>
              <Box sx={{ pr: { md: 3, xs: 0 } }}>
                <ISWC t={t} />
                <Lyrics t={t} />
              </Box>
            </Grid>
            <Grid item xs={12} md={3} sx={{ pt: { md: 5, xs: 0 } }}>
              <Box sx={{ mb: 3 }} />
              <SectionTipCard
                title={t('form.tips.iswc.title')}
                color="orange"
                readMoreLink="/help/alternate-song-titles"
                readMoreText={t('form.tips.iswc.button')}
                alertContent={
                  <RichTooltipContent
                    title={t('form.tips.iswc.title')}
                    contents={[t('form.tips.iswc.content')]}
                  />
                }
              >
                <Typography
                  variant="body1"
                  component="span"
                  dangerouslySetInnerHTML={{
                    __html: t('form.tips.iswc.content') as string,
                  }}
                />
              </SectionTipCard>
              <Box sx={{ mb: 3 }} />
              <SectionTipCard
                title={t('form.tips.lyrics.title')}
                color="green"
                readMoreLink="/help/alternate-song-titles"
                readMoreText={t('form.tips.lyrics.button')}
                alertContent={
                  <RichTooltipContent
                    title={t('form.tips.lyrics.expanded-title')}
                    type="ol"
                    contents={lyricGuidelines}
                  />
                }
              >
                <Typography
                  variant="body1"
                  component="span"
                  dangerouslySetInnerHTML={{
                    __html: t('form.tips.lyrics.content') as string,
                  }}
                />
              </SectionTipCard>
            </Grid>
          </Grid>

          {/* Save Button */}
          <Grid
            container
            item
            xs={12}
            md={9}
            sx={{ justifyContent: 'space-between', pr: { md: 3 }, mt: 2 }}
          >
            <Button
              variant="outlined"
              sx={{
                paddingLeft: '2rem',
                paddingRight: '2rem',
              }}
              onClick={() => {
                // go back to the previous page
                navigate('/songs/manage');
              }}
            >
              {t('form.buttons.cancel')}
            </Button>
            <Button
              data-testid="song-form-submit"
              type="submit"
              variant="contained"
              disabled={!formMethods.formState.isValid || loading}
              sx={{
                backgroundColor: 'secondary.main',
                color: 'text.primary',
                '&:hover': {
                  color: 'white',
                },
                paddingLeft: '2rem',
                paddingRight: '2rem',
              }}
            >
              {loading ? t('form.loading') : t('form.buttons.update')}
            </Button>
          </Grid>
        </Container>
      </FormProvider>
    </form>
  );
}

export default EditSongPage;
