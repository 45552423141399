/* eslint-disable react/prop-types */
import { ApolloError } from '@apollo/client';
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import AccessManagementInvitePage from '~/account/components/AccessManagement/AccessManagementInvitePage';
import AccessManagementPage from '~/account/components/AccessManagement/AccessManagementPage';
import AccountHistoryPage from '~/account/components/AccountHistory/AccountHistoryPage';
import AccountInformationPage from '~/account/components/AccountInformation/AccountInformationPage';
import ChangePasswordPage from '~/account/components/ChangePassword/ChangePasswordPage/ChangePasswordPage';
import ForceChangePasswordPage from '~/account/components/ChangePassword/ForceChangePasswordPage';
import TaxDocumentationPage from '~/account/components/TaxDocumentation/TaxDocumentationPage';
import VerifyIdentityPage from '~/account/components/VerifyIdentity/VerifyIdentityPage/VerifyIdentityPage';
import ErrorPage from '~/base/components/ErrorPage';
import FlagCheckedChildren from '~/base/components/FlagCheckedChildren';
import Footer from '~/base/components/Footer/Footer';
import FormBanner from '~/base/components/FormBanner';
import { FormBannerType } from '~/base/components/FormBanner/FormBanner';
import Header from '~/base/components/Header';
import RedirectAuth from '~/base/components/Redirect/RedirectAuth';
import RequireAuth from '~/base/components/RequireAuth/RequireAuth';
import ErrorPageEnum from '~/base/enums/errorPageEnums';
import FlagNameEnum from '~/base/enums/flagNameEnum';
import FlagTypeEnum from '~/base/enums/flagTypeEnum';
import { defaultAuthRefreshInterval } from '~/cache';
import translationLocales from '~/constants/translationLocales';
import DashboardPage from '~/dashboard/components/DashboardPage';
import ForgotPasswordPage from '~/login/components/ForgotPasswordPage/ForgotPasswordPage';
import LoginPage from '~/login/components/LoginPage';
import Logout from '~/login/components/Logout';
import useLoginRefreshInterval from '~/login/hooks/useLoginRefreshInterval';
import OnboardingGetStartedPage from '~/onboarding/components/OnboardingGetStartedPage';
import OnboardingPage from '~/onboarding/components/OnboardingPage';
import SignupPage from '~/signup/components/SignupPage';
import TermsPage from '~/signup/components/TermsPage';
import AddRecordingsPage from '~/songs/components/AddRecordingsPage';
import AddSongPage from '~/songs/components/AddSongPage';
import EditSongPage from '~/songs/components/EditSongPage/EditSongPage';
import ManageSongsPage from '~/songs/components/ManageSongsPage';
import AddOutsideSongwriterPage from '~/songwriters/components/AddOutsideSongwriterPage/AddOutsideSongwriterPage';
import AddSongwriterPage from '~/songwriters/components/AddSongwriterPage';
import IncreaseSongwritersPage from '~/songwriters/components/IncreaseSongwritersPage/IncreaseSongwritersPage';
import ManageSongwritersPage from '~/songwriters/components/ManageSongwritersPage';
import SongwriterDetailPage from '~/songwriters/components/SongwriterDetailPage';
import YoutubeClaimsPage from '~/youtube/components/YoutubeClaimsPage';

if (import.meta.env.VITE_ENV !== 'prod') {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

function App() {
  const [processError, setProcessError] = useState<string>('');
  const { t } = useTranslation(translationLocales);
  useLoginRefreshInterval(
    defaultAuthRefreshInterval,
    (error: ApolloError) => {
      if (error) {
        setProcessError(t('errors.refresh_token', { ns: 'translation' }));
      }
    },
    () => setProcessError(''),
  );

  return (
    <BrowserRouter>
      <FormBanner
        text={processError}
        type={FormBannerType.WARNING}
        dismissButton
      />
      <Header />
      <main className="main-content" data-testid="main-content">
        {import.meta.env.VITE_MAINTENANCE === 'true' && (
          <Routes>
            <Route path="*" element={<Navigate to="/maintenance" />} />
            <Route
              path="/maintenance"
              element={<ErrorPage type={ErrorPageEnum.SERVER_MAINTENANCE} />}
            />
          </Routes>
        )}
        {import.meta.env.VITE_MAINTENANCE === 'false' && (
          <Routes>
            <Route path="*" element={<Navigate to="/error/404" />} />
            <Route
              path="/error/404"
              element={<ErrorPage type={ErrorPageEnum.ERROR_404} />}
            />
            <Route
              path="/error/500"
              element={<ErrorPage type={ErrorPageEnum.ERROR_500} />}
            />
            <Route path="/maintenance" element={<Navigate to="/login" />} />
            <Route path="/" element={<Navigate to="/login" />} />
            <Route
              path="/login"
              element={
                <LoginPage t={(text: string) => t(text, { ns: 'login' })} />
              }
            />
            <Route
              path="/signup"
              element={
                <SignupPage t={(text: string) => t(text, { ns: 'signup' })} />
              }
            />
            <Route path="/terms-of-service" element={<TermsPage />} />
            <Route
              path="/forgot-password"
              element={
                <ForgotPasswordPage
                  t={(text: string) => t(text, { ns: 'forgotpassword' })}
                />
              }
            />
            <Route path="/logout" element={<Logout />} />

            <Route element={<RequireAuth />}>
              <Route
                path="/frozen"
                element={<ErrorPage type={ErrorPageEnum.ERROR_FROZEN} />}
              />
              <Route
                path="/dashboard"
                element={
                  <DashboardPage
                    t={(text: string) => t(text, { ns: 'dashboard' })}
                  />
                }
              />

              <Route
                path="/youtube/allowlisted"
                element={
                  <YoutubeClaimsPage
                    allowlisted
                    t={(text: string) => t(text, { ns: 'youtube' })}
                  />
                }
              />

              <Route
                path="/youtube"
                element={
                  <YoutubeClaimsPage
                    allowlisted={false}
                    t={(text: string) => t(text, { ns: 'youtube' })}
                  />
                }
              />
              <Route
                path="/royalties"
                element={<RedirectAuth url="/royalties" />}
              />
              <Route
                path="/referrals"
                element={<RedirectAuth url="/referrals" />}
              />
              <Route
                path="/songs/:id/edit"
                element={
                  <FlagCheckedChildren
                    name={FlagNameEnum.EDIT_SONG_REDIRECT}
                    type={FlagTypeEnum.FLAG}
                    alternative={<RedirectAuth url="/songs/:id/edit" />}
                  >
                    <EditSongPage
                      t={(text: string) => t(text, { ns: 'songs' })}
                    />
                  </FlagCheckedChildren>
                }
              />
              {['/songs', '/songs/manage'].map((path) => (
                <Route
                  key={`songs_${path}`}
                  path={path}
                  element={
                    <ManageSongsPage
                      t={(text: string) => t(text, { ns: 'songs' })}
                    />
                  }
                />
              ))}
              <Route
                path="/songs/add"
                element={
                  <FlagCheckedChildren
                    name={FlagNameEnum.ADD_SONG_REDIRECT}
                    type={FlagTypeEnum.FLAG}
                    alternative={<RedirectAuth url="/songs/add" />}
                  >
                    <AddSongPage
                      t={(text: string) => t(text, { ns: 'songs' })}
                    />
                  </FlagCheckedChildren>
                }
              />
              <Route
                path="/songs/:id/recordings"
                element={
                  <FlagCheckedChildren
                    name={FlagNameEnum.ADD_RECORDING_REDIRECT}
                    type={FlagTypeEnum.FLAG}
                    alternative={
                      <RedirectAuth url="/songs/:id/recordings/add/#spotify" />
                    }
                  >
                    <AddRecordingsPage
                      t={(text: string) => t(text, { ns: 'songs' })}
                    />
                  </FlagCheckedChildren>
                }
              />

              <Route
                path="/songs/:id/recordings/:rid"
                element={
                  <FlagCheckedChildren
                    name={FlagNameEnum.EDIT_RECORDING_REDIRECT}
                    type={FlagTypeEnum.FLAG}
                    alternative={
                      <RedirectAuth url="/songs/:id/recordings/:rid" />
                    }
                  >
                    {/* TODO: Need ability to edit Recording in REACT */}
                    <RedirectAuth url="/songs/:id/recordings/:rid" />
                  </FlagCheckedChildren>
                }
              />

              {[
                '/songwriters',
                '/songwriters/manage',
                '/songwriters/manage/client',
                '/songwriters/manage/outside',
              ].map((path) => (
                <Route
                  path={path}
                  key={`songwriters_${path}`}
                  element={
                    <ManageSongwritersPage
                      t={(text: string) => t(text, { ns: 'songwriters' })}
                    />
                  }
                />
              ))}

              <Route
                path="/songwriters/:id"
                element={
                  <SongwriterDetailPage
                    t={(text: string) => t(text, { ns: 'songwriters' })}
                  />
                }
              />
              <Route
                path="/songwriters/add"
                element={
                  <AddSongwriterPage
                    t={(text: string) => t(text, { ns: 'songwriters' })}
                  />
                }
              />
              <Route
                path="/songwriters/add/outside"
                element={
                  <AddOutsideSongwriterPage
                    t={(text: string) => t(text, { ns: 'songwriters' })}
                  />
                }
              />
              <Route
                path="/songwriters/buy"
                element={
                  <IncreaseSongwritersPage
                    t={(text: string) => t(text, { ns: 'songwriters' })}
                  />
                }
              />
              <Route
                path="/restore-admin"
                element={<RedirectAuth url="/customer-service/restore-admin" />}
              />
              <Route
                path="/get-started"
                element={<OnboardingGetStartedPage />}
              />
              <Route path="/onboarding" element={<OnboardingPage />} />
              <Route
                path="/account/account-information"
                element={
                  <AccountInformationPage
                    t={(text: string) => t(text, { ns: 'account' })}
                  />
                }
              />
              <Route
                path="/account/change-password"
                element={
                  <ChangePasswordPage
                    t={(text: string) => t(text, { ns: 'account' })}
                  />
                }
              />
              <Route
                path="/account/reset-requested"
                element={
                  <ForceChangePasswordPage
                    t={(text: string) => t(text, { ns: 'account' })}
                  />
                }
              />
              <Route
                path="/account/billing-information"
                element={<Navigate to="/account/account-information" />}
              />
              <Route
                path="/account/notifications"
                element={<Navigate to="/account/account-information" />}
              />
              <Route
                path="/account/tax-documentation"
                element={<TaxDocumentationPage />}
              />
              <Route
                path="/account/direct-deposit"
                element={<Navigate to="/account/tax-documentation" />}
              />
              <Route
                path="/account/past-statements-upload"
                element={<Navigate to="/account/account-information" />}
              />
              <Route
                path="/account/account-history"
                element={
                  <AccountHistoryPage
                    t={(text: string) => t(text, { ns: 'account' })}
                  />
                }
              />
              <Route
                path="/account/access-management"
                element={
                  <AccessManagementPage
                    t={(text: string) => t(text, { ns: 'account' })}
                  />
                }
              />
              <Route
                path="/account/access-management/:id"
                element={
                  <AccessManagementInvitePage
                    editMode
                    t={(text: string) => t(text, { ns: 'account' })}
                  />
                }
              />
              <Route
                path="/account/access-management/invite"
                element={
                  <AccessManagementInvitePage
                    editMode={false}
                    t={(text: string) => t(text, { ns: 'account' })}
                  />
                }
              />
              <Route
                path="/account/verify-identity"
                element={
                  <VerifyIdentityPage
                    t={(text: string) => t(text, { ns: 'account' })}
                  />
                }
              />
            </Route>
          </Routes>
        )}
      </main>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
