import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { FormProvider, useForm } from 'react-hook-form';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import FormAutoComplete from '~/base/components/FormAutoComplete';
import LoadingIndicator from '~/base/components/LoadingIndicator';
import usePageTitle from '~/hooks/usePageTitle';
import { T } from '~/types/Translator';
import { useSongByIdQuery } from '~/types/generated/graphql';
import SpotifyList from './SpotifyList';

export default function AddRecordingsPage({ t }: { t: T }) {
  usePageTitle(t('page.titles.addRecordings'));
  const navigate = useNavigate();
  const formMethods = useForm();

  const params = useParams();
  const {
    data: songData,
    loading,
    error,
  } = useSongByIdQuery({
    variables: { id: Number(params.id || '') },
  });

  const titlesList = (title = '', alternates: { title: string }[] = []) => {
    return [title].concat(alternates.map((a: { title: string }) => a?.title));
  };

  return (
    <FormProvider {...formMethods}>
      <form>
        <Container>
          <Grid container gap={4}>
            <Grid container item direction="column">
              <Grid>
                <Typography variant="h2">
                  {t('form.titles.addRecordings')}
                </Typography>
              </Grid>
              <Grid>
                <Typography variant="h4">
                  For{' '}
                  <Link component={RouterLink} to="/songs/add">
                    {songData ? (
                      songData?.song?.title
                    ) : (
                      <LoadingIndicator size={4} />
                    )}
                  </Link>
                </Typography>
              </Grid>
            </Grid>
            <Grid container item>
              <FormAutoComplete
                id="song-title-select"
                testid="song-title-select"
                name="songTitle"
                label="Select Title"
                options={titlesList(
                  songData?.song?.title,
                  songData?.song?.alternatetitleSet,
                ).map((o) => ({
                  choiceId: o,
                  choiceLabel: o,
                }))}
              />
            </Grid>
            <Grid container item>
              {loading || error || !songData?.song ? (
                <LoadingIndicator size={4} />
              ) : (
                <SpotifyList artist="" t={t} title={songData.song?.title} />
              )}
            </Grid>
            <Grid container item>
              <Button variant="outlined" onClick={() => navigate(`/songs/add`)}>
                {t('form.buttons.backToSong')}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </form>
    </FormProvider>
  );
}
