/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-nested-ternary */
import CircleIcon from '@mui/icons-material/Circle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import SyncIcon from '@mui/icons-material/Sync';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import {
  Step,
  StepConnector,
  stepConnectorClasses,
  StepIconProps,
  StepLabel,
  Stepper,
  styled,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import LoadingIndicator from '~/base/components/LoadingIndicator';
import SectionTitle from '~/base/components/SectionTitle/SectionTitle';
import { SongType } from '~/types/generated/graphql';
import { Translator } from '~/types/Translator';

const CustomConnector = styled(StepConnector)(() => ({
  left: 'calc(-100% + 20px)',
  right: 'calc(90% + 0px)',
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 20,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: 'linear-gradient( 136deg, #606BF8 0%, #031C9B 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#031C9B',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 15,
    border: '1px solid #ccc',
    backgroundColor: '#fff',
    borderRadius: 1,
  },
}));

const CustomStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ ownerState }) => ({
  backgroundColor: '#fff',
  color: '#fff',
  border: '1px solid #ccc',
  zIndex: 1,
  width: 50,
  height: 50,

  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    border: '3px solid #031C9B',
    color: '#031C9B',
    backgroundColor: '#fff',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    border: '1px solid #031C9B',
    backgroundColor: '#031C9B',
    color: '#031C9B',
  }),
}));

function CustomStepIcon({ icon, active, completed, className }: StepIconProps) {
  const icons: { [index: string]: React.ReactElement } = {
    1: <CircleIcon />,
    2: <CircleIcon />,
    3: <CircleIcon />,
  };

  return (
    <CustomStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(icon)]}
    </CustomStepIconRoot>
  );
}

interface RegistrationStatusProps extends Translator {
  song: SongType | undefined;
}

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const month = date.toLocaleDateString('en-US');
  const day = date.getDate();

  return { month, day };
};

export default function RegistrationStatus({
  t,
  song,
}: RegistrationStatusProps) {
  const [steps, setSteps] = useState<string[]>([]);
  const [activeStep, setActiveStep] = useState(0);

  // First useEffect: Set the steps displayed based on the song displayStatus.
  useEffect(() => {
    if (song) {
      if (song.displayStatus === 'awaiting_recording') {
        setSteps(['awaiting_recording', 'processing', 'sent', 'registering']);
      } else {
        setSteps(['monitoring', 'processing', 'sent', 'registering']);
      }
    }
  }, [song]);

  // Second useEffect: Set the active step based on the updated steps array
  useEffect(() => {
    if (song && steps.length > 0) {
      setActiveStep(steps.indexOf(song.displayStatus as string));
    }
  }, [song, steps]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SectionTitle>
          {t('form.section.registration-status.title')}
        </SectionTitle>
      </Grid>
      {(!song || !steps.length) && <LoadingIndicator size={50} />}
      {song && steps.length > 0 && (
        <>
          <Grid item xs={12}>
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              connector={<CustomConnector />}
            >
              {steps.map((step, index) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const currentStepDate = song?.displayStatusLog?.[steps[index]];

                const isLastStep = index === steps.length - 1;

                return (
                  <Step key={`${step}`}>
                    <StepLabel
                      StepIconComponent={(props: StepIconProps) => (
                        <CustomStepIcon
                          {...props}
                          icon={index + 1}
                          completed={
                            activeStep > index ||
                            (isLastStep && activeStep === index)
                          }
                          active={activeStep === index}
                        />
                      )}
                      sx={{
                        alignItems: 'flex-start',
                        '.MuiStepLabel-alternativeLabel': { textAlign: 'left' },
                      }}
                    >
                      <Grid container flexDirection="column">
                        <Grid item>
                          {index < activeStep || isLastStep ? (
                            <TaskAltIcon sx={{ color: '#7FC832' }} />
                          ) : index > activeStep ? (
                            <RadioButtonUncheckedIcon />
                          ) : (
                            <SyncIcon sx={{ color: '#B85D00' }} />
                          )}
                        </Grid>
                        <Grid item>
                          {currentStepDate &&
                            index <= activeStep &&
                            (() => {
                              const { month, day } =
                                formatDate(currentStepDate);

                              return (
                                <>
                                  <Typography variant="body1">
                                    {month}
                                  </Typography>
                                  <Typography variant="h3" component="span">
                                    {day}
                                  </Typography>
                                </>
                              );
                            })()}
                        </Grid>
                        <Grid item>
                          <Typography variant="body1" component="span">
                            {t(
                              `form.section.registration-status.steps.${steps[index]}.title`,
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container sx={{ mt: '1rem', p: { xs: '.5rem' } }}>
              <Grid item xs={12} md={3} sx={{ alignContent: 'center' }}>
                <SyncIcon sx={{ color: '#B85D00', fontSize: '4rem' }} />
              </Grid>
              <Grid item xs={12} md={9}>
                <Typography variant="h3">
                  {t(
                    `form.section.registration-status.steps.${steps[activeStep]}.current.title`,
                  )}
                </Typography>
                <Typography variant="body1">
                  {t(
                    `form.section.registration-status.steps.${steps[activeStep]}.current.copy`,
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container sx={{ marginTop: '1rem', p: { xs: '.5rem' } }}>
              <Grid item>
                <Typography variant="h3">
                  {t(
                    `form.section.registration-status.steps.${steps[activeStep]}.next.title`,
                  )}
                </Typography>
                <Typography variant="body1">
                  {t(
                    `form.section.registration-status.steps.${steps[activeStep]}.next.copy`,
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
}
