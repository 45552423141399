import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PercentIcon from '@mui/icons-material/Percent';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Popover from '@mui/material/Popover';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { debounce, isNull } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import FormAutoComplete from '~/base/components/FormAutoComplete';
import FormInput from '~/base/components/FormInput';
import SectionTitle from '~/base/components/SectionTitle/SectionTitle';

import { Translator } from '~/types/Translator';
import {
  InterestedpartySongWriterSongwriterTypeChoices,
  SongtrustUserType,
  SongwriterType,
  useCurrentUserQuery,
  useSongSongwritersLazyQuery,
} from '~/types/generated/graphql';

import CopySplits from '../CopySplits';
import OverClaimingModal from '../OverClaimingModal';
import AddOutsideSongwriter from './AddOutsideSongwriter';
import Cowriters from './Cowriters';
import {
  alternateSongwriter,
  findSameName,
  nameArray,
  renderLabel,
} from './utils';

export default function Songwriters({ t }: Translator) {
  const { data: currentUser } = useCurrentUserQuery();
  const [fetchSongwriters, { loading, data }] = useSongSongwritersLazyQuery();
  const { control, setValue, watch } = useFormContext();
  const { append: addCowriter } = useFieldArray({
    name: 'cowriters',
    control,
  });

  const songwriterPercentage = watch('songwriter.percent');
  const songwriter = watch('songwriter');
  const cowriters = watch('cowriters');
  const overclaiming = watch('overclaiming');
  const overclaimingAgreement = watch('overclaimingAgreement');

  const [addSongwriter, setAddSongwriter] = useState<{
    anchorElement: (EventTarget & HTMLButtonElement) | null;
    open: boolean;
  }>({
    anchorElement: null,
    open: false,
  });
  const [outsideSongwriterOpen, setOutsideSongwriterOpen] = useState(false);
  const [overclaimingOpen, setOverclaimingOpen] = useState(false);
  const [copySplitsOpen, setCopySplitsOpen] = useState(false);
  const [total, setTotal] = useState(0);
  const [inputSongwriterValue, setInputSongwriterValue] = useState(
    songwriter?.inputValue,
  );
  const openModal = overclaimingAgreement
    ? undefined
    : () => setOverclaimingOpen(true);
  const maxValue = overclaiming === 'yes' ? 99.99 : 100;

  const fetchWithDebounce = useMemo(
    () =>
      debounce((input: string) => {
        fetchSongwriters({
          variables: {
            searchFor: input,
            songwriterType:
              InterestedpartySongWriterSongwriterTypeChoices.Client,
          },
        });
      }, 300),
    [],
  );

  useEffect(() => {
    if (inputSongwriterValue?.length > 1)
      fetchWithDebounce(inputSongwriterValue);
  }, [inputSongwriterValue, fetch]);

  useEffect(() => {
    if (songwriterPercentage > maxValue)
      setValue('songwriter.percent', maxValue);
  }, [maxValue, overclaiming, songwriterPercentage]);

  useEffect(() => {
    setTotal(
      [{ percent: songwriterPercentage }, ...cowriters].reduce(
        (p, { percent }) => {
          const result = p + Number(percent);
          return result;
        },
        0,
      ),
    );
  }, [songwriterPercentage, cowriters]);

  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const xs = useMediaQuery(theme.breakpoints.only('xs'));

  return (
    <>
      <SectionTitle
        action={
          <>
            <Button
              data-testid="new-songwriters-button"
              variant="outlined"
              size="small"
              startIcon={<AddIcon fontSize="small" />}
              onClick={(e) => {
                if (overclaimingAgreement) {
                  setAddSongwriter(() => ({
                    anchorElement: e.currentTarget,
                    open: true,
                  }));
                } else setOverclaimingOpen(true);
              }}
            >
              {t('form.buttons.newSongwriter')}
            </Button>
            {md && (
              <Popover
                id="add-songwriter-popover"
                anchorEl={addSongwriter.anchorElement}
                open={addSongwriter.open}
                onClose={() =>
                  setAddSongwriter((state) => ({ ...state, open: false }))
                }
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <Link
                  href="/songwriters/add"
                  underline="none"
                  sx={{
                    display: 'inline-flex',
                    padding: '0.3rem 0.2rem 0.2rem',
                  }}
                >
                  <AddIcon />
                  {t('form.buttons.songtrustSongwriter')}
                </Link>
                <hr />
                <Button
                  data-testid="toggle-outside-songwriter"
                  startIcon={<AddIcon />}
                  onClick={() => setOutsideSongwriterOpen((state) => !state)}
                >
                  {t('form.buttons.outsideSongwriter')}
                </Button>
              </Popover>
            )}
            {xs && (
              <SwipeableDrawer
                id="add-songwriter-popover"
                anchor="bottom"
                open={addSongwriter.open}
                onOpen={() =>
                  setAddSongwriter((state) => ({ ...state, open: true }))
                }
                onClose={() =>
                  setAddSongwriter((state) => ({ ...state, open: false }))
                }
              >
                <Link
                  href="/songwriters/add"
                  underline="none"
                  sx={{
                    display: 'inline-flex',
                    padding: '0.3rem 0.2rem 0.2rem',
                    justifyContent: 'center',
                  }}
                >
                  <AddIcon />
                  {t('form.buttons.songtrustSongwriter')}
                </Link>
                <hr />
                <Button
                  data-testid="toggle-outside-songwriter"
                  startIcon={<AddIcon />}
                  onClick={() => setOutsideSongwriterOpen((state) => !state)}
                >
                  {t('form.buttons.outsideSongwriter')}
                </Button>
              </SwipeableDrawer>
            )}
          </>
        }
      >
        {t('form.section.songwriters.title')}
      </SectionTitle>

      <OverClaimingModal
        open={
          !isNull(overclaiming) && overclaimingAgreement
            ? false
            : overclaimingOpen
        }
        setOpen={setOverclaimingOpen}
        t={t}
      />

      {/* Copy previous splits   */}
      <Grid
        container
        direction="column"
        gap={4}
        sx={{ display: 'grid' }}
        onClick={openModal}
        onKeyDown={openModal}
      >
        <Grid container item direction="row" xs={12} md={7} marginBottom={1}>
          <Button
            data-testid="copy-splits-button"
            variant="contained"
            color="success"
            startIcon={<ContentCopyIcon fontSize="small" />}
            onClick={() => {
              setCopySplitsOpen(true);
            }}
          >
            {t('form.buttons.copySplits')}
          </Button>
          {copySplitsOpen && (
            <CopySplits
              t={t}
              currentUser={
                currentUser?.loggedInSongtrustUser as SongtrustUserType
              }
              open={copySplitsOpen && !overclaimingOpen}
              setOpen={setCopySplitsOpen}
            />
          )}
        </Grid>

        {/* Total ownership */}
        <Grid container>
          <Grid item xs={12} md={6.35}>
            <fieldset style={{ borderRadius: '0.5rem' }}>
              <legend style={{ textAlign: 'center' }}>Total Ownership</legend>
              <Typography variant="h3" sx={{ textAlign: 'center' }}>
                {total || 0.0}%
              </Typography>
            </fieldset>
          </Grid>
        </Grid>

        {/* Songwriter */}
        <Grid container item direction="row" gap={{ xs: 2, md: 4 }}>
          <Grid container item xs={7} md={4}>
            <FormAutoComplete
              id="songwriter-input"
              testid="songwriter-container"
              sx={{
                width: '100%',
              }}
              label={t('form.labels.songwriter')}
              name="songwriter.songwriterId"
              placeholder={t('form.placeholders.songwriter')}
              inputValue={inputSongwriterValue}
              options={
                data?.songwriters?.edges
                  ?.map((edge, _, edges) => {
                    const alteration = renderLabel(
                      alternateSongwriter(
                        edge?.node as SongwriterType,
                        edges
                          .filter(
                            (e) =>
                              !nameArray(e?.node as SongwriterType).every(
                                (v, i) =>
                                  v ===
                                  nameArray(edge?.node as SongwriterType)[i],
                              ),
                          )
                          .some((e) =>
                            findSameName(
                              nameArray(e?.node as SongwriterType),
                              nameArray(edge?.node as SongwriterType),
                            ),
                          ),
                        currentUser?.loggedInSongtrustUser as SongtrustUserType,
                        t,
                      ),
                      true,
                    );
                    return {
                      choiceId: edge?.node?.id ?? '',
                      choiceLabel:
                        `${[
                          ...nameArray(edge?.node as SongwriterType),
                          alteration,
                        ].reduce(
                          (s: string, c) => s.concat(renderLabel(c)),
                          '',
                        )}` ?? '',
                    };
                  })
                  .filter((c) => {
                    const s = cowriters.map(
                      (o: { songwriterId: number }) => o.songwriterId,
                    );
                    return !s.includes(parseInt(c.choiceId, 10));
                  }) ?? []
              }
              autocompleteSX={{
                '.MuiAutocomplete-popupIndicator': {
                  transform: 'none',
                },
                width: '100%',
              }}
              popupIcon={<SearchIcon fontSize="small" />}
              forcePopupIcon
              onOptionSelected={() => undefined}
              inputProps={{ 'data-testid': `songwriter-input` }}
              onInputChange={(_: React.SyntheticEvent, value: string) => {
                setInputSongwriterValue(value);
              }}
              freeSolo
              displayRequired
              loading={loading}
              required
            />
          </Grid>
          <Grid
            container
            item
            md={2}
            sx={{ flexBasis: 'min-content', flexGrow: 1 }}
          >
            <FormInput
              id="songwriter-percentage"
              label="Percentage"
              name="songwriter.percent"
              inputProps={{
                'data-testid': 'songwriter-percent',
                type: 'number',
                min: 0,
                max: overclaiming === 'yes' ? 99.99 : 100,
                step: 0.01,
              }}
              endAdornment={<PercentIcon fontSize="small" />}
              sx={{ width: '100%' }}
              displayRequired
              required
            />
          </Grid>
        </Grid>

        {/* Cowriters */}
        <AddOutsideSongwriter
          t={t}
          open={outsideSongwriterOpen}
          setOpen={setOutsideSongwriterOpen}
          addCowriter={addCowriter}
        />
        <Grid container item xs={12} gap={3}>
          <Cowriters t={t} />
        </Grid>
      </Grid>
    </>
  );
}
